@import './colors';
@import './vars';

// typefaces:
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Space+Grotesk:wght@100;200;300;400;500;600;700;800;900&display=swap');

@each $theme-name, $theme-colorList in $themes {
  // Reassign page colors using theme colors
  $skyBg: map-get($theme-colorList, skyBg);
  $mtn-light: map-get($theme-colorList, mtn-light);
  $mtn-light-desat: map-get($theme-colorList, mtn-light-desat);
  $mtn-lighter: map-get($theme-colorList, mtn-lighter);
  $mtn-dark: map-get($theme-colorList, mtn-dark);
  $accent1: map-get($theme-colorList, accent1);
  $accent1-hover: map-get($theme-colorList, accent1-hover);
  $accent2: map-get($theme-colorList, accent2);
  $text-light: map-get($theme-colorList, text-light);
  $text-darker: map-get($theme-colorList, text-darker);

  html.theme-#{'' + $theme-name} {
    body {
      margin: 0;
      position: relative;

      // hide x overflow for mobile / prevent pinch to zoom out
      overflow-x: hidden;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      background-color: $mtn-light;
    }

    // NavBar

    #navRow {
      height: 15vh;
      width: 100%;

      display: grid;

      align-items: center;

      grid-template-areas: '.. .. .. about work logoCont logoCont projects contact .. .. ..';

      @media only screen and (max-width: $br-desktop) {
        grid-template-areas: '.. .. about work work logoCont logoCont projects projects contact .. ..';
      }

      @media only screen and (max-width: $br-tablet) {
        grid-template-areas: '.. about about work work logoCont logoCont projects projects contact contact ..';
      }

      @media only screen and (max-width: 900px) {
        grid-template-columns: 30px repeat(10, 1fr) 30px;
      }

      grid-template-columns: repeat(12, 1fr);

      #navAbout {
        grid-area: about;
        justify-self: start;
      }

      #navWork {
        grid-area: work;
      }

      #navProjects {
        grid-area: projects;
      }

      #navContact {
        justify-self: end;
        grid-area: contact;
      }

      #logoCont {
        grid-area: logoCont;

        svg {
          height: 4vh;
          filter: drop-shadow(0px 0px 20px $skyBg);
        }
      }
    }

    // Nav Items
    li > a.navLink {
      font-family: $ttf-hd;
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-light;
      text-decoration: none;
      transition: color 0.5s ease;

      &:hover {
        color: $accent1;
      }
    }

    // AM Logo -- in Hero section and Footer
    #logoAccent {
      fill: $accent1;
    }

    #logoCont > a:hover #logoAccent {
      fill: $text-light;
    }

    #logoM,
    #logoA {
      fill: $text-light;
    }

    #logoCont > a:hover #logoM,
    #logoCont > a:hover #logoA {
      fill: $accent1;
    }

    #logoM,
    #logoA,
    #logoAccent {
      transition: fill 0.5s ease;
    }

    #mobileNavRow {
      height: 15vh;
      width: 100%;

      display: grid;

      align-items: center;

      grid-template-areas: '.. .. .. logoCont logoCont logoCont logoCont logoCont logoCont menuBtn menuBtn menuBtn';

      grid-template-columns: repeat(12, 1fr);

      &__menuBtn {
        grid-area: menuBtn;
        justify-self: center;

        // hamburger menu animated icon by: Mikael Ainalem
        // found at: https://codepen.io/ainalem/pen/wvKOEMV?editors=1100
        .menu {
          background-color: transparent;
          border: none;
          cursor: pointer;
          display: flex;
          padding: 0;

          svg {
            height: 70px;
            width: 70px;
          }

          .line {
            fill: none;
            stroke: white;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke 250ms ease;
          }
          .line1 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
          }
          .line2 {
            stroke-dasharray: 60 60;
            stroke-width: 6;
          }
          .line3 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
          }
          .opened .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
          }
          .opened .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
          }
          .opened .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
          }

          &:hover {
            svg .line {
              stroke: $accent1;
            }
          }
        }
        // end of hamburger menu icon styles
      }

      #logoCont {
        grid-area: logoCont;

        svg {
          height: 6vh;
        }
      }
    }

    #logoCont {
      // applies to all AM logos
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #navRow > li {
      display: block;
      text-align: center;
      list-style: none;

      a.navLink {
        font-family: $ttf-hd;
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: none;
      }
    }

    // header for non-home pages
    .pageHead {
      position: relative;
      height: 18vh;
      background-color: $skyBg;
      clip-path: ellipse(70% 100% at 50% 0%);

      &__Canvas {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &__Nav {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }

    // duplicate block ^^
    ul.mobileNavLinks {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      height: 60vh;
      justify-content: space-evenly;

      li {
        // hide by default
        visibility: hidden;
        opacity: 0;

        a {
          font-size: 2.5rem;
        }
      }

      #navAbout > a {
        font-size: 1.25rem;
      }
    }

    /* Landing page */
    #landBgContainer {
      position: relative;
      height: 100vh;
      background-color: $skyBg;
    }

    #landContentContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      /* separate all text from sky */
      text-shadow: 0px 0px 10px $skyBg;

      /* Landing page content */

      #spacingRow {
        height: 17vh;

        @media only screen and (max-width: $br-phone) {
          height: 8vh;
        }
      }

      #headRow {
        text-align: center;
        position: relative;

        h1 {
          font-weight: 800;
          font-size: 7vmin;
          color: $accent1;

          @media only screen and (max-width: $br-phone) {
            font-size: 15vw;
          }
        }

        h3 {
          font-weight: 500;
          font-size: 5vmin;
          color: $text-light;

          line-height: calc(
            100% + 25px
          ); // fix for emojis causing the line height to increase during animation

          @media only screen and (max-width: $br-phone) {
            font-size: 6.5vw;
            padding-top: 10px;
            line-height: 7vw; // mobile browsers don't seem to support the calc(100% + 25px) method, so this has been manually set
          }
        }

        h1,
        h3 {
          @media only screen and (max-width: $br-phone) {
            text-align: left;
            margin: auto 10px auto 10px;
          }
        }

        #scrollIndicator {
          position: absolute;
          top: 50%;
          right: auto;
          left: 0;
          transform: translateY(-50%) translateX(-100%);
          height: 300px;
          width: 300px;
          user-select: none;

          @media only screen and (max-width: $br-phone) {
            top: 0;
            right: 0;
            left: auto;
            transform: translateY(-30%) translateX(20%);
            height: 220px;
            width: 220px;
          }

          .scroll_indicator {
            height: 100%;
            width: 100%;

            &__text {
              font-size: 13.03px;
              font-family: $ttf-handwritten;
              letter-spacing: 0.05em;
            }

            &__text,
            &__upArrow,
            &__downArrow {
              fill: $text-light;
            }
          }
        }

        #clickMe {
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translateY(200px) translateX(25px);
          height: 80px;
          width: 80px;
          user-select: none;

          @media only screen and (max-width: $br-phone) {
            display: none;
          }

          .click_me {
            &:hover .click_me__text {
              fill: $accent1;
              cursor: pointer;
            }

            &__text {
              font-size: 12px;
              font-family: $ttf-handwritten;
              letter-spacing: 0.05em;
              fill: $text-light;
            }

            &__squiggle {
              fill: $accent1;
            }
          }
        }
      }
    }

    #landSVG {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 125vh;

      @media only screen and (max-width: $br-tablet) {
        height: 130vh;
      }
    }

    /* hero section bg SVG styles */

    #mtn-dark {
      fill: $mtn-dark;
    }

    g#mtn-light > path {
      fill: $mtn-light;
    }

    /* End of hero section bg SVG styles */

    /* SVG styles */

    g#mtn-light > path {
      transform: translateY(1px);
    }

    /* End of SVG styles */

    /* Canvas Styles */

    #landBgContainer > canvas {
      display: block;
      background-color: $skyBg;
    }

    /* Below Fold */
    .pageContent {
      display: flex;
      flex-direction: column;

      .contentHead > h1 {
        font-weight: 700;
        font-size: 3.5rem;
        color: $text-light;

        @media only screen and (max-width: $br-phone) {
          font-size: 12vw;
          text-align: center;
        }
      }

      div > h3 {
        font-weight: 600;
        font-size: 2rem;
        color: $text-light;
      }

      span.headAccent {
        color: $accent1;
      }

      p {
        font-family: $ttf-body;
        color: $text-light;
      }

      &__row {
        margin-top: 25vh;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: min-content;

        @media only screen and (max-width: $br-phone) {
          grid-template-columns: 30px repeat(10, 1fr) 30px;
        }

        .contentHead {
          grid-area: hd;
        }
      }

      .r1 {
        grid-template-areas:
          '.. .. .. hd hd hd hd hd hd .. .. ..'
          'sc sc sc sc sc sc sc sc sc sc sc sc';

        // change header position for smaller screen sizes
        @media only screen and (max-width: $br-desktop) {
          grid-template-areas:
            '.. .. hd hd hd hd hd hd hd hd .. ..'
            'sc sc sc sc sc sc sc sc sc sc sc sc';
        }

        @media only screen and (max-width: $br-tablet) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            'sc sc sc sc sc sc sc sc sc sc sc sc';
        }

        #fgSkills {
          // for parent grid:
          grid-row: sc;
          grid-column: 3 / 11;

          height: 85vh;
          width: 100%;
          margin: 0 auto;

          // make this its own grid:                 For visualization:
          display: grid; // grid-template-areas:
          grid-template-columns: repeat(3, 1fr); //     'img img img'
          grid-template-rows: repeat(2, 1fr); //     'img img img';

          // responsive fgSkills logos
          @media only screen and (max-width: $br-desktop) {
            grid-column: 2 / 12;
          }

          @media only screen and (max-width: $br-tablet) {
            grid-template-columns: repeat(2, 1fr); //     'img img img'
            grid-template-rows: repeat(3, 1fr); //     'img img img';
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .fgSkill {
            max-height: 100px; //🟧 NEED MEDIA QUERIES FOR SMALLER SCREEN SIZES

            @media only screen and (max-width: $br-tablet) {
              // Skills don't display on mobile when the width is undefined
              width: 10vw;
            }

            // style each logo
            .cls-1,
            .cls-2 {
              fill: #d1d1d1;
            }
            .cls-2 {
              opacity: 0.8;
            }
            .cls-3 {
              fill: #9e9e9e;
            }
          }

          // Overwrite Logo Sizing:
          .mySqlSize {
            width: 200px;
          }
          .phpSize {
            width: 100px;
          }

          @media only screen and (max-width: $br-tablet) {
            // Overwrite Logo Sizing:
            .mySqlSize {
              max-width: 20vw;
            }
            .phpSize {
              max-width: 10vw;
            }
          }
        }

        #bgSkills {
          grid-area: sc;
          margin: 0 auto;
          width: 100%;
          height: 85vh;

          #panel-1,
          #panel-2 {
            path {
              opacity: 0.1;
              fill: white;
            }
          }
        }
      }

      .r2 {
        grid-template-areas: '.. .. .. hd hd hd hd hd hd .. .. ..';
        row-gap: 10vh;

        @media only screen and (max-width: $br-desktop) {
          grid-template-areas: '.. .. hd hd hd hd hd hd hd hd .. ..';
        }

        @media only screen and (max-width: $br-tablet) {
          grid-template-areas: '.. hd hd hd hd hd hd hd hd hd hd ..';
        }
      }

      .r3 {
        grid-template-areas:
          '.. .. .. hd hd hd hd hd hd .. .. ..'
          '.. .. .. ws ws ws ws ws ws .. .. ..'
          '.. .. .. hl hl hl pr1 pr1 pr2 pr2 pr2 ..'
          '.. .. .. ws2 ws2 ws2 ws2 ws2 ws2 .. .. ..'
          '.. .. .. pjs pjs pjs pjs pjs pjs .. .. ..';

        grid-template-rows: 90px 10vh min-content 50px min-content 0px;

        @media only screen and (min-width: $br-big-desktop) {
          // increase height of 'ws' row on bigger screensizes
          grid-template-rows: 90px 10vw min-content 20vh min-content 0px;
        }

        @media only screen and (max-width: $br-desktop) {
          grid-template-areas:
            '.. .. hd hd hd hd hd hd hd hd .. ..'
            '.. .. .. ws ws ws ws ws ws .. .. ..'
            '.. .. hl hl hl hl pr1 pr1 pr2 pr2 pr2 ..'
            '.. .. .. ws2 ws2 ws2 ws2 ws2 ws2 .. .. ..'
            '.. .. pjs pjs pjs pjs pjs pjs pjs pjs .. ..';
        }

        @media only screen and (max-width: $br-half-desktop) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            '.. ws ws ws ws ws ws ws ws ws ws ..'
            '.. .. hl hl hl hl pr1 pr1 pr2 pr2 pr2 ..'
            '.. ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ..'
            '.. pjs pjs pjs pjs pjs pjs pjs pjs pjs pjs ..';
        }

        @media only screen and (max-width: $br-tablet) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            '.. ws ws ws ws ws ws ws ws ws ws ..'
            '.. hl hl hl hl hl pr1 pr1 pr2 pr2 pr2 ..'
            '.. ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ..'
            '.. pjs pjs pjs pjs pjs pjs pjs pjs pjs pjs ..';
        }

        @media only screen and (max-width: 900px) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            '.. ws ws ws ws ws ws ws ws ws ws ..'
            '.. hl hl hl hl hl hl hl hl hl hl ..'
            '.. ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ws2 ..'
            '.. pjs pjs pjs pjs pjs pjs pjs pjs pjs pjs ..';

          grid-template-rows: 90px 30px min-content 15vh min-content 0px;
        }

        @media only screen and (max-width: $br-phone) {
          overflow: hidden; // prevents horizontal scrolling on mobile devices
          padding-bottom: 100px; // padding looks better on mobile
        }
      }

      .r4 {
        grid-template-areas:
          '.. .. .. hd hd hd hd hd hd .. .. ..'
          '.. .. .. body body body body body body .. .. ..'
          '.. .. .. form form form form form form .. .. ..';

        @media only screen and (max-width: $br-desktop) {
          grid-template-areas:
            '.. .. hd hd hd hd hd hd hd hd .. ..'
            '.. .. body body body body body body body body .. ..'
            '.. .. form form form form form form form form .. ..';
        }

        @media only screen and (max-width: $br-tablet) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            '.. body body body body body body body body body body ..'
            '.. form form form form form form form form form form ..';
        }

        grid-template-rows: 90px min-content min-content;

        row-gap: 5vh;

        .body {
          grid-area: body;

          p {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 2;
            text-indent: 5ch;
            text-align: justify;

            a {
              color: $accent1;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .formCont {
          grid-area: form;
          margin-bottom: 35vh;

          .formStatus {
            width: 100%;
            margin: 30px 0 30px 0;
            border-radius: 10px;
            padding: 10px;
            box-sizing: border-box;

            &--success {
              background-color: rgba($accent1, 0.7);
            }

            &--error {
              background-color: rgba($accent2, 0.7);
            }
          }

          .formGrid {
            display: grid;
            grid-template-areas:
              'name email phone'
              'subject subject subject'
              'message message message'
              'submit .. ..';

            @media only screen and (max-width: 900px) {
              grid-template-areas:
                'name name name'
                'email email email'
                'phone phone phone'
                'subject subject subject'
                'message message message'
                'submit .. ..';
            }

            grid-template-rows: repeat(4, min-content);
            grid-template-columns: repeat(3, 1fr);
            column-gap: 3%;
            row-gap: 10%;

            &__name {
              grid-area: name;
            }

            &__email {
              grid-area: email;
            }

            &__phone {
              grid-area: phone;
            }

            &__subject {
              grid-area: subject;
            }

            &__message {
              grid-area: message;
            }

            &__submit {
              grid-area: submit;
            }

            div:not(.formGrid__submit) {
              display: grid;
              row-gap: 7px;

              label {
                span {
                  text-transform: uppercase;
                  color: $accent1;
                  font-family: $ttf-body;
                  font-size: 1rem;
                  letter-spacing: 1px;
                  font-weight: 600;
                }

                strong {
                  // required mark (*)
                  font-size: 14pt;
                  font-weight: 500;
                  color: $accent2;
                }
              }

              input.textfield,
              textarea {
                // text
                font-size: 14pt;
                font-weight: 500;
                font-family: $ttf-body;
                color: $text-darker;

                // box
                background-color: rgba(255, 255, 255, 0.03);
                border-radius: 10px 10px 0px 0px;
                padding: 10px;
                box-sizing: border-box; // fixes padding causing element to overflow parent
                border: 0px;
                border-bottom: 2px solid rgba(255, 255, 255, 0.2);
                transition: border-bottom 250ms ease-in-out;
                width: 100%;

                &::placeholder {
                  color: rgba(255, 255, 255, 0.2);
                }

                &:focus {
                  outline: none;
                  border-bottom: 2px solid rgba($accent1, 0.8);
                }
              }

              input.textfield:not([value='']):invalid,
              textarea:not(:empty):invalid {
                border-bottom: 2px solid $accent2;
              }

              textarea {
                resize: none;
                height: 250px;
              }

              input[type='submit'] {
                border: 0px;
                -webkit-appearance: none;
              }

              input[type='submit']:disabled,
              input[type='submit'][disabled] {
                cursor: not-allowed;
                background-color: #9c9c9c;
                color: #363636;
              }
            }
          }
        }
      }

      .r5 {
        grid-template-areas:
          '.. .. .. hd hd hd hd hd hd .. .. ..'
          '.. .. .. body body body body body body .. .. ..';

        @media only screen and (max-width: $br-desktop) {
          grid-template-areas:
            '.. .. hd hd hd hd hd hd hd hd .. ..'
            '.. .. body body body body body body body body .. ..';
        }

        @media only screen and (max-width: $br-tablet) {
          grid-template-areas:
            '.. hd hd hd hd hd hd hd hd hd hd ..'
            '.. body body body body body body body body body body ..';
        }

        .contentHead > h1 {
          @media only screen and (max-width: $br-phone) {
            text-align: left;
          }
        }

        row-gap: 3vh;

        // projectsSection projPage styles
        .projPage__headSec__tagList,
        p,
        a,
        li {
          font-family: $ttf-body;
        }

        .projPage__headSec__tagList {
          color: darken($text-darker, 15);
          list-style: none;
          padding: 0;
          margin-top: 2rem;
          font-weight: 400;
          font-size: 1.2rem;

          // all list elements
          & * {
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 2px;

            margin-right: 10px;
            &::after {
              content: '|';
              margin-left: 10px;
            }

            &:last-child::after {
              content: none;
            }
          }
        }

        .projPage__carouselCont {
          grid-area: carouselCont;

          background-color: $mtn-light-desat;
          border-radius: 30px 0px 30px 0px;
          padding: 2rem 0;
          min-height: 400px;

          display: grid;
          grid-template-areas: 'chev1 carousel chev2';
          grid-template-columns: 10% 6fr 10%;

          align-items: center;
          justify-content: center;

          position: relative;

          @media only screen and (max-width: $br-phone) {
            display: none;
          }

          &__carousel {
            grid-column: chev1-start / chev2-end;
            grid-row: 1;
            height: 90%;

            @media only screen and (max-width: $br-phone) {
              height: 100%;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            &__content {
              filter: brightness(0.85); // darken image slightly
              width: 80%;

              &__browserSVG {
                width: 100%;
                display: block;
                margin: 0 auto;
                z-index: 1;
              }

              &__screenshot {
                z-index: 0;
                width: 100%;
                max-height: 500px;
                object-fit: cover;
                object-position: center top;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
              }
            }
          }

          &__chev1 {
            grid-area: chev1;
          }

          &__chev2 {
            grid-area: chev2;
          }

          &__chev {
            // border: 1px solid green;
            display: grid;
            justify-content: center;
            align-items: center;
            font-family: $ttf-body;
            font-size: 20px;
            letter-spacing: 1px;
            position: relative;
            height: 100%;

            // disable text selection/highlighting
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;

            // accent mark
            &::after {
              content: '\2014';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              color: $accent1;
            }
          }

          // carousel btn group styles
          & > .projPage__btns {
            // live site / source code buttons container
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .projPage__btns {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .projPage__btns__sourceBtn {
          display: flex;
          max-width: 50px;
          justify-content: center;
          align-items: center;
          margin-left: 30px;

          & > svg {
            width: 100%;
            height: 100%;
          }

          & > svg > * {
            fill: $accent1;
            transition: all 500ms ease;
          }

          &:hover svg > *,
          &:focus svg > * {
            fill: $accent1-hover;
          }
        }

        .projPage__spanIcons {
          color: $text-light;
          transition: color 250ms ease;

          &:hover,
          &:focus {
            color: $accent1;
            text-decoration: none;
            cursor: pointer;
          }
        }

        .projPage__body {
          // BLOG POST STYLES
          grid-area: body;

          // type scale formatting
          font-weight: 400;
          line-height: 1.75;

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin: 4rem 0 0.5rem;
            font-family: $ttf-hd;
            font-weight: 500;
            line-height: 1.3;
            color: $text-light;
          }

          h1 {
            margin: 0 auto;
            font-size: 3.052rem;
          }

          h2 {
            font-size: 2.441rem;
          }

          h3 {
            font-size: 1.953rem;
          }

          h4 {
            font-size: 1.563rem;
          }

          h5 {
            font-size: 1.25rem;
          }

          .projPage__headSec__tagList,
          p,
          a,
          li {
            font-size: 1.2rem;
          }

          p:not(.projPage__Date) {
            margin-top: 0.5rem;
            margin-bottom: 2.5rem;
          }

          small,
          .text_small {
            font-size: 0.75rem;
          }

          p,
          table {
            color: $text-light;
          }

          p,
          ul,
          ol {
            // for all links inside paragraphs or lists:
            a {
              color: $accent1;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          p,
          ol,
          ul,
          table {
            font-family: $ttf-body;
            line-height: 2;
          }

          ol,
          ul {
            margin: 2em 0;
            color: $text-darker;
            text-decoration: $accent1;

            li::marker {
              color: $accent1;
            }
          }

          table {
            border-collapse: collapse;
            text-align: left;
            margin: 50px auto;

            thead > tr {
              color: $accent1;
              border-bottom: 1px solid $accent1;

              th {
                font-weight: 500;
              }
            }

            tr > * {
              // cell padding
              padding: 10px 15px;
            }
          }

          pre {
            background-color: $mtn-light-desat;
            padding: 1em;
            border-radius: 10px;
            font-size: 1rem;
            white-space: pre-wrap; // wrap end of line

            code {
              // big code blocks
              color: $text-light;
            }
          }

          figure {
            margin-top: 0;
            margin-right: auto;
            margin-bottom: 2em;
            margin-left: auto;
            width: fit-content;

            img {
              border-radius: 15px 0px 15px 0px;
              border: 20px solid $mtn-light-desat;
              max-width: 90%;
            }

            figcaption {
              font-family: $ttf-body;
              color: $accent1;
            }
          }
        }
      }
    }

    // GLOBAL STYLES

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 10px 0px 10px 0px;
      font-family: $ttf-hd;
    }

    // For 'Learn more ->' and 'See live demo' btns
    .btn-primary {
      display: inline-block;
      text-align: center;
      border-radius: 5px;
      text-decoration: none;
      border: 0px;

      font-family: $ttf-body;
      color: $mtn-light;
      background-color: $accent1;
      font-size: 1rem;
      padding: 0.75em 1.25em;
      font-weight: 600;
      // transform: translateY(0px);
      cursor: pointer;

      transition: background-color 500ms ease;

      &:hover {
        background-color: $accent1-hover;
      }
    }

    .btn-secondary {
      color: $accent1;
      border: 1px solid $accent1;
      background: transparent;

      &:hover {
        color: $text-darker;
      }
    }

    .btn-action {
      font-size: 0.75rem;
      border-radius: 50px;
      padding: 0.8em;
    }

    .box-shadow-1dp {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.24),
        0px 2px 6px rgba(0, 0, 0, 0.12);
    }

    .svg-shadow-1dp {
      filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.24))
        drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.12));
    }
  }

  // utilities that don't need theming:
  .fadeIn {
    animation: fadeIn 250ms ease-in-out forwards;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}
