// media query breakpoints:
$br-big-desktop: 1930px;
$br-desktop: 1700px;
$br-half-desktop: 1250px;
$br-tablet: 1050px;
$br-phone: 630px;

// fonts-faces:
$ttf-hd: 'Raleway', sans-serif;
$ttf-body: 'Open Sans', sans-serif;
$ttf-handwritten: 'Gloria Hallelujah', cursive;
