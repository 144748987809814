/* Scrollbar stlying */
$scrollbarBG: rgba(0, 0, 0, 0.1);
$thumbBG: rgba(255, 255, 255, 0.4);
$thumbBGHov: rgba(255, 255, 255, 0.5);

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: $scrollbarBG;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: $thumbBG;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $thumbBGHov;
}
